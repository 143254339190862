<template>
  <div>
    <div v-if="statusStep !== ''">
      <v-row class="pt-0 px-0 ma-0 d-flex justify-center">
        <v-col
          cols="3"
          sm="2"
          class="d-flex flex-column align-center pa-0 mx-0"
        >
          <v-img
            :src="require(`@/assets/icons/${statusIcon}.svg`)"
            alt
            contain
            class="icon"
            :width="$vuetify.breakpoint.xs ? '50px' : '80px'"
          />
        </v-col>

        <v-col cols="4" sm="2" class="pa-0 d-flex align-center pa-0">
          <v-card elevation="0" color="secondary" width="100%" height="3px" />
        </v-col>

        <v-col
          cols="3"
          sm="2"
          class="d-flex flex-column align-center pa-0 mx-0"
        >
          <v-img
            :src="require(`@/assets/icons/${stepIcon}.svg`)"
            alt
            contain
            class="icon"
            :width="$vuetify.breakpoint.xs ? '50px' : '80px'"
          />
        </v-col>
      </v-row>

      <v-row class="pb-9 px-0 ma-0 d-flex justify-center">
        <v-col cols="3" sm="2" class="text-center pa-0 mx-0">
          <div
            :class="$vuetify.breakpoint.xs ? 'text-mobile-size' : ''"
            class="text-sm-h4 font-weight-bold"
            style="line-height: normal"
          >
            {{ statusText }}
          </div>
        </v-col>
        <v-col cols="4" sm="2" class="pa-0"></v-col>
        <v-col cols="3" sm="2" class="text-center pa-0 mx-0">
          <div
            :class="$vuetify.breakpoint.xs ? 'text-mobile-size' : ''"
            class="text-sm-h4 font-weight-bold"
            style="line-height: normal"
          >
            {{ statusStep }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row v-else-if="statusStep === ''" class="px-0 ma-0">
      <v-col cols="1"></v-col>
      <v-col cols="10" class="d-flex flex-column align-center px-0 mx-0">
        <v-img
          :src="require(`@/assets/icons/${statusIcon}.svg`)"
          alt
          contain
          class="icon"
          :width="$vuetify.breakpoint.xs ? '100px' : '150px'"
        />

        <div class="mb-5 text-h4 text-sm-h3 font-weight-bold text-center">
          {{ statusText }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    statusText: {
      type: String,
      default: "",
    },
    statusStep: {
      type: String,
      default: "",
    },
    statusIcon: {
      type: String,
      default: "",
    },
    stepIcon: {
      type: String,
      default: "CheckmarkCircle",
    },
  },
};
</script>
<style scoped lang="scss">
.text-mobile-size {
  font-size: 10px !important;
}
</style>
