<template>
  <div
    class="body-container px-10 px-md-0"
    :class="` ${
      $vuetify.breakpoint.xs ? 'purple-gradient-mobile' : 'purple-gradient'
    }`"
  >
    <div class="pa-0 pt-sm-6">
      <v-btn
        depressed
        plain
        dark
        :ripple="false"
        :to="{ name: 'UserMainPage' }"
        class="pt-6 pt-sm-0"
      >
        <v-img eager src="@/assets/icons/LogoZaturnaHorizontal.svg" />
      </v-btn>
      <v-card elevation="0" height="50px" color="transparent"></v-card>
    </div>
    <div
      v-if="isPackagePayment || (!loading && confirmedPayment)"
      class="d-flex flex-column align-div-content"
    >
      <div class="d-flex justify-center align-center">
        <div class="d-flex flex-column align-center text-center">
          <div class="text-h2 text-sm-h1 font-weight-bold secondary--text">
            Confirmación de pago
          </div>
          <div class="mt-4 text-h4">
            Se ha realizado tu pago de manera exitosa.
          </div>
        </div>
      </div>

      <div>
        <PaymentStatus
          status-text="Pago exitoso"
          status-step="Reservación confirmada"
          status-icon="CheckmarkCircleGreen"
          step-icon="CheckmarkCircle"
        />
      </div>

      <div class="d-flex justify-center">
        <div class="d-flex flex-column align-center">
          <div>
            <Button
              text="Ir a mis reservaciones"
              aria-label="Ir a mis reservaciones"
              url="UserReservations"
            />
          </div>
          <div class="mt-5">
            <router-link
              class="font-weight-bold text-decoration-underline"
              style="color: inherit"
              :to="{ name: 'UserPayments' }"
            >
              Ir a pagos realizados
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <v-container
      v-else-if="loading"
      class="d-flex flex-column align-div-content align-center"
    >
      <v-progress-circular
        indeterminate
        color="secondary"
        :size="100"
      ></v-progress-circular>
    </v-container>
    <div
      v-else-if="!loading && !confirmedPayment"
      class="d-flex flex-column align-div-content"
    >
      <div
        class="text-body-1 text-sm-h3 text-center font-weight-bold secondary--text"
      >
        Ocurrió un error al confirmar tu pago
      </div>
      <div class="d-flex justify-center mt-12 mt-md-16">
        <Button
          text="Ir a mis reservaciones"
          aria-label="Ir a mis reservaciones"
          url="UserReservations"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentStatus from "@/components/User/Payments/PaymentStatus.vue";
import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";
import { useMutation } from "@/graphql/index";
import { CONFIRM_PAYMENT } from "@/graphql/mutations";

export default {
  name: "AdvancePaymentConfirmation",
  components: {
    PaymentStatus,
    Button,
  },
  data() {
    return {
      loading: true,
      confirmedPayment: false,
    };
  },
  computed: mapState({
    isPackagePayment() {
      return !this.$route.params.qr_id || !this.$route.params.payment_id;
    },
    user(state) {
      return state.user;
    },
  }),
  watch: {
    user: {
      async handler(user) {
        if (this.isPackagePayment || !user.id) return;
        if (!this.paymentConfirming && !this.paymentConfirmed) {
          this.paymentConfirming = true;
          await this.confirmPayment();
          this.paymentConfirmed = true;
          this.paymentConfirming = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async confirmPayment() {
      const props = {
        quotationRequestId: parseInt(this.$route.params.qr_id),
        paymentId: parseInt(this.$route.params.payment_id),
        userId: parseInt(this.user.id),
      };

      try {
        const { errors } = await useMutation(CONFIRM_PAYMENT, props);

        if (errors) throw errors;

        this.confirmedPayment = true;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 75%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.purple-gradient-mobile {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 80%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.align-div-content {
  display: flex;
  justify-content: center;
  height: 80vh;
}
</style>
